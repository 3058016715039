<template>
  <div class="clipshadow" style="position: relative; z-index: 90" @wheel.stop>

    <main class="relative flex flex-row items-center h-[50px] mt-[10px]"
          style="z-index: 100; border-radius: 100px; --bg: var(--main-fg); --fg: var(--main-bg); background: rgb(var(--bg)); color: rgb(var(--fg));">
          <span class="h-full flex flex-row px-5 text-lg font-extrabold b3 clipshape" hidden>
            <img class="object-contain w-6 h-6 mr-2.5" src="img/logo.png"/>
            Свч Кит
          </span>
      <span style="width: 420px"></span>
<!--      <span class="font-light"> Конструктор заказа (0.5.1) {{editorStore.mode}} {{editorStore.portsValid}}</span>-->
      <span class="font-light"> Конструктор заказа 1.0</span>
      <span class="flex-1">
          </span>


      <div class="mr-3">
        <div class="flex select-none gap-1">
          <input id="show-ports-input" type="checkbox" class="" v-model="editorStore.showPorts"/>
          <label for="show-ports-input">Порты</label>
        </div>
      </div>

      <div>
        <button

          v-if="authStore.isAuthenticated"
          type="submit"
          class="button button-positive w-[120px] h-[40px] clipshape-all text-white mr-5"
          @click="syncWithTilda"
        >
          Синхр. с Tilda
        </button>

      </div>
      <div>
        <button
          v-if="authStore.isAuthenticated"
          type="submit"
          class="button button-positive w-[80px] h-[40px] clipshape-all text-white "
          @click="logout"
        >
          Выйти
        </button>
        <button
          v-else
          type="submit"
          class="button button-positive w-[80px] h-[40px] clipshape-all text-white "
          @click="$router.push('/signin')"
        >
          Войти
        </button>
      </div>

      <div class="flex items-center">
        <div class="relative m-2">
          <button class="button itembutton m-2" @click="toTildaShop">
            <Icon name="cart" size="24"></Icon>
          </button>
          <span v-if="editorStore.objects.length>0"
                class="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full w-6 h-6 flex items-center justify-center -mt-2 -mr-2">
              {{ editorStore.objects.length }}
          </span>
        </div>
        <!-- <div class="relative inline-block mr-5">
          <button class="button itembutton text-white px-4 py-2 rounded" @click="a()">
            <Icon name="trash" size="24" ></Icon>
          </button>
          <span v-if="editorStore.objects.length>0" class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold rounded-full w-8 h-8 flex items-center justify-center">
              {{ editorStore.objects.length }}
          </span>
        </div> -->

        <!-- Свитч(переключатель) -->
        <LayerSelect></LayerSelect>

        <!-- Кнопка Проект -->
        <DropdownMenu></DropdownMenu>

      </div>

      <!-- Выступ в хидере в начале -->
      <span class="absolute top-0 right-0 h-full px-[15px] b1 clipshape" style="z-index: -1;"></span>
      <em class="absolute top-0 left-0 w-[420px] h-[60px] b1 clipshape"></em>

    </main>

  </div>


  <!-- Палитра -->
  <Pallete></Pallete>
  <ComponentEditorForm v-if="palleteStore.form.selected"></ComponentEditorForm>

</template>

<script setup>

import {onMounted, reactive, ref, watch} from "vue";
import {useFetch} from "@/composables/useFetch";
import DropdownMenu from "@/components/DropdownMenu";
import LayerSelect from "@/components/LayerSelect";
import Pallete from "@/components/Pallete";
import ComponentEditorForm from "@/components/ComponentEditorForm";
import {usePalleteStore} from "@/stores/pallete";
import {useEditorStore, editorModes} from "@/stores/editor";
import Icon from "@/components/Icon";
import {useAuthStore} from "@/stores/useAuthStore";
import {usePopUpsStore} from "@/stores/popups";

const fetch = useFetch()

const editorStore = useEditorStore()
const palleteStore = usePalleteStore()
const authStore = useAuthStore()
const popupsStore = usePopUpsStore()

const myElement = ref(null);

const data = reactive({
  showProjectMenu: false,
  isButtonHovered: false,
  switchState: 1,
  showPallete: true,
  items: [],
  input: ''
})

// let clientId = localStorage.getItem('clientId')
// fetch.request(`/api/v1/carts/${clientId}`, {
//   })
//   .then((data) => {
//     console.log('запрос корзины тестовое место', data)
//   })
//   .catch((error) => {
//     console.error(error);
//   })


const syncWithTilda = () => {
  fetch.request(`/api/v1/tilda/sync`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then((data) => {
      popupsStore.tildaSyncResult.visible = true
      popupsStore.tildaSyncResult.count = data.count
    })
    .catch((error) => {
      popupsStore.tildaSyncResult.visible = true
      popupsStore.tildaSyncResult.count = -1
    })
}

watch(() => editorStore.objects.length, () => {
  let cartItems = {}
  for (let object of editorStore.objects) {
    if (!(object.component.id in cartItems)) {
      cartItems[object.component.id] = 1
    } else {
      cartItems[object.component.id] += 1
    }
  }
  let items = JSON.stringify(cartItems)
  let clientId = localStorage.getItem('clientId')
  fetch.request(`/api/v1/carts/${clientId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      items: items,
    }
  })
    .then((data) => {
      console.log('сохранение корзины', data)
    })
    .catch((error) => {
      console.error(error);
    })
})
// function a(){
//   console.log('editorStore.objects', editorStore.objects.length)
//   if ( editorStore.objects){
//     console.log('a')
//   }
//   else { console.log('b')}
// }

const toTildaShop = () => {
  popupsStore.notAvailableComponents.visible = false
  popupsStore.cartRedirect.visible = false
  popupsStore.cartRedirectPortValid.visible = false

  if (editorStore.notAvailableComponents.length > 0) {
    popupsStore.notAvailableComponents.visible = true
  }
  if (editorStore.objects.length === 0) {
    popupsStore.cartRedirect.visible = true
  }
  if (!editorStore.portsValid) {
    popupsStore.cartRedirectPortValid.visible = true
  }
  if (!(popupsStore.cartRedirectPortValid.visible || popupsStore.cartRedirect.visible || popupsStore.notAvailableComponents.visible))
  {
    const clientId = localStorage.getItem('clientId')
    if (!clientId) {
      alert('Ошибка: Client ID не указан')
    }
    // const url = `http://svch-kit.ru/b90b0082-d2b8-437b-98cb-c42dce6dd9df?clientId=${clientId}`
    const url = `https://svch-kit.ru/catalog?clientId=${clientId}`
    window.open(url, '_blank');

  }
}


const logout = () => {
  authStore.logout()
  palleteStore.form.selected = null
}

</script>

<style scoped>

</style>