<template>
  <div class="window absolute top-0 bottom-0 left-0 w-[390px] mt-[20px] mb-[15px] ml-[35px] pointer-events-none "
       data-palletevisible="1"
       style="z-index: 100;"
       @wheel.stop
  >
    <main class="flex-1">
      <header class="window-title pointer-events-auto"
              :class="{'clipshape-top' : is_expand, 'clipshape-all' : !is_expand, }">
        <div class="flex" style="width: 170px; flex-shrink: 0;">
          <a @click="changeShowPallete" class="button button-transparent opacity-50">
            <Icon v-if="is_expand" name="angle-up"></Icon>
            <Icon v-else name="angle-down"></Icon>
          </a>
          <span class="window-name px-0 " style="min-width: 0"> Палитра </span>
          <a v-if="authStore.isAuthenticated" class="button button-transparent mx-1.25" @click="createButtonHandler">
            + </a>
          <a class="button button-transparent mx-1.25 " :class="{'bg-green-400': Object.entries(palleteStore.filters).length > 0}"  @click="filters_show = (filters_show==true) ? false : true;">
            <Icon v-if="filters_show" name="cross"></Icon>
            <Icon v-else name="filter"></Icon>
          </a>
        </div>
        <div class="flex relative">
          <i v-if="query" class="button justify-center absolute top-0 right-0" @click="clearInput">
            <Icon name="cross"></Icon>
          </i>
          <i v-else
             class="w-[30px] h-[30px] flex items-center justify-center absolute top-0 right-0 pointer-events-none opacity-70"
          >
            <Icon name="find"></Icon>
          </i>

          <input class="form-input w-full pr-7.5" placeholder="Поиск" v-model="query"/>
        </div>
      </header>
      <div v-if="filters_show"
      class=" bg-[white] window-content overflow-y-auto px-0 py-2.5 flex-1 pointer-events-auto flex-grow overflow-auto"
        style="max-height: calc(100vh - 80px)"
        v-show="is_expand" :class="{'clipshape-bottom' : is_expand, 'clipshape-all' : !is_expand, }">
        <span class="window-name pl-5 " style="min-width: 0"> Фильтры </span>
<!--        {{selectedFIlters}}-->
<!--        <hr>-->
<!--        {{filters}}-->
<!--        <hr>-->
<!--        {{palleteStore.filters}}-->
<!--        -->
        <div v-for="filter in selectedFIlters" :key="filter.id" class="mx-[40px] my-[5px]">
          <div v-if="['STRING', 'NUMBER'].includes(filter.type)" class="flex flex-row space-x-[5px] items-center" >
            <span v-tooltip="filter.name" class="fieldname w-[100px] whitespace-nowrap text-ellipsis overflow-hidden" style="flex-shrink: 0"> {{ filter.name }} </span>
            <div v-if="filter.type === 'STRING'" class="form-field">
              <input v-model="filter.value" class="form-input w-full" :placeholder="filter.name" >
            </div>
            <div v-else-if="filter.type === 'NUMBER'" class="form-field flex whitespace-nowrap items-center" >
              <label class="mr-[5px]">от</label>
              <input v-model="filter.value.from" class="form-input w-[70px] mr-[5px]" type="number" placeholder="0" >
              <label class="mr-[5px]">{{ filter.unit }}</label>
              <label class="mr-[5px]">до</label>
              <input v-model="filter.value.to" class="form-input w-[70px]" type="number" placeholder="0" >
              <label class="ml-[5px]">{{ filter.unit }}</label>
            </div>
            <div v-else-if="filter.type === 'BOOLEAN'" class="flex select-none gap-1">
              <input :checked="filter.value" @change="filter.value = $event.target.checked ? true : null" type="checkbox"  />
            </div>
          </div>

          <div v-else-if="filter.type === 'BOOLEAN'"  class="flex flex-row space-x-[5px] items-center justify-between">
            <span v-tooltip="filter.name" class="fieldname  whitespace-nowrap text-ellipsis overflow-hidden" style="flex-shrink: 0"> {{ filter.name }} </span>
            <div class="flex select-none gap-1">
              <input :checked="filter.value" @change="filter.value = $event.target.checked ? true : null" type="checkbox"  />
            </div>
          </div>
        </div>


        <div class="flex justify-around mx-[50px] my-[10px]">
          <a class="button button-transparent" @click="reset()">Сбросить</a>
          <button
            type="submit"
            class="button button-positive w-[80px] h-[40px] clipshape-all text-white ml-[20px]"
            @click="save()"
          >Применить</button>
        </div>
        
      </div>


      <div v-else
        class=" bg-[white] window-content overflow-y-auto px-0 py-2.5 flex-1 pointer-events-auto flex-grow overflow-auto"
        style="max-height: calc(100vh - 80px)"
        v-show="is_expand" :class="{'clipshape-bottom' : is_expand, 'clipshape-all' : !is_expand, }">
        <div v-for="category in palleteStore.categories" :key="category.id" class="relative category">
          <details v-if="category.id in componentsByCategoryId" @toggle="toggleIcon(category)" open>
            <summary class="py-[5px] flex  items-center w-full">
              <div class="w-[140px]"></div>
              <div class="px-[15px] w-[150px] "
                style="font-size: 17px; font-weight: 900; border-left: 5px solid rgb(var(--fg), 0.35); ">
                {{ category.name }}
              </div>
              <Icon :name="category.isOpen ? 'angle-up' : 'angle-down'" style="fill: black;"></Icon>
            </summary>

            <div v-if="category.id in componentsByCategoryId" class="relative">
              <div v-for="component in componentsByCategoryId[category.id]" :key="component.id" class="component"
                  :class="{'component-selected': (component === palleteStore.form.selected || component === editorStore?.selected?.component)}"
                  @contextmenu.prevent="componentContextMenuHandler(component)">
                <!-- <div class="flex px-7.5 py-1.5 select-none " @mousedown="mouseDownHandler($event, component)"> -->
                <div class="flex px-7.5 py-1.5 select-none " @pointerdown="mouseDownHandler($event, component)">
                  <div class="flex-1 pr-2.5 ">
                    <div class="font-extrabold text-lg"> {{ component.name }}</div>
                    <div class="text-lg leading-none mt-1.25 opacity-70 "> {{ component.description }}</div>
                    <template v-if="authStore.isAuthenticated">
                      <div v-if="component.tilda_product_id" class="bg-[green] w-fit px-2 py-1 my-1 rounded text-white">ОК</div>
                      <div v-else class="bg-[red] w-fit px-2 py-1 my-1 rounded text-white" >Не указан ID продукта</div>
                    </template>

                  </div>

                  <div>
                    <div v-if="settingsStore.useFlags && component.country_of_manufacture" class="absolute right-0 mr-7.5" style="border: 1px solid #000000; background-color: white; width: 24px; height: 16px">
                      <img
                        :src="`${fetch.baseURL}/static/images/flags/${component.country_of_manufacture}.svg`"
                        style="width: 100%; height: 100%; object-fit: cover"

                      >
                    </div>
                    <img v-if="component.cover_image" class="unit-img" draggable="false"
                         :src="component.cover_image || '/frontend/empty.png'">
                    <img v-else class="unit-img" draggable="false"
                         src="/frontend/empty.png">
                  </div>

                </div>

              </div>
            </div>
        </details>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import Icon from "@/components/Icon";

const {v4: uuidv4} = require('uuid');

import {onMounted, reactive, ref, watch, onBeforeMount, computed} from "vue";
import {useFetch} from "@/composables/useFetch";
import {usePalleteStore} from "@/stores/pallete";
import {editorModes, useEditorStore} from "@/stores/editor";
import {useAuthStore} from "@/stores/useAuthStore";
import {useSettingsStore} from "@/stores/settings";

const fetch = useFetch()

const palleteStore = usePalleteStore()
const editorStore = useEditorStore()
const authStore = useAuthStore()
const settingsStore = useSettingsStore()

const myElement = ref(null);
const is_expand = ref(true)
const filters = ref([])
const filters_show = ref(false)
let selectedFIlters = reactive([])

function toggleIcon(category) {
  category.isOpen = !category.isOpen;
}


fetch.request(`/api/v1/filters/`, {
})
  .then((data) => {
    console.log(data)
    filters.value = data
    console.log(filters.value)
    for (let filter of filters.value){
      if (filter.type === 'NUMBER') {
        selectedFIlters.push({
          'id': filter.id,
          'name': filter.name,
          'type': filter.type,
          'value': { 'from': null, 'to': null},
        })
      } else  {
        selectedFIlters.push({
          'id': filter.id,
          'name': filter.name,
          'type': filter.type,
          'value': null,
        })
      }
    }
  })
  .catch((error) => {
    console.error(error);
  })

const query = ref(null)



watch(() => query.value, async (query) => {
  await palleteStore.getComponents(query)
})

function reset(){
  for (let filter of selectedFIlters){
    if (filter.type === 'NUMBER') {
      filter.value.from = null
      filter.value.to = null
    } else {
      filter.value = null
    }
    palleteStore.filters = {}
    palleteStore.getComponents(query.value)
    filters_show.value = false
  }
}

function save(){
  palleteStore.filters = {}
  // console.log('selectedFIlters', selectedFIlters)
  const filteredArray = selectedFIlters.filter(item => {
    if (item.value === null) {
      return false;
    }
    return !(item.value.from === null && item.value.to === null);
  });

  // console.log('filteredArray', filteredArray);

  console.log('palleteStore.filters', palleteStore.filters)
  // Формируем query параметры
  const queryParams = filteredArray.map(item => {
    if (item.type === 'NUMBER') {
      if (item.value.from == ''){item.value.from=null}
      if (item.value.to == ''){item.value.to=null}
      palleteStore.filters[item.id] = `int1=${item.value.from};int2=${item.value.to}`
      return `${item.id}=int1=${item.value.from};int2=${item.value.to}`;
    }
    else if (item.type === 'BOOLEAN') {
      if (item.value == true){
        palleteStore.filters[item.id] = item.value
        return `${item.id}=${item.value}`;
      }
    }
    else {
      palleteStore.filters[item.id] = item.value
      return `${item.id}=${item.value}`;
    }
  }).filter(param => param !== null) // Убираем null
    .join('&'); // Объединяем в одну строку
  console.log('palleteStore.filters', palleteStore.filters)
  console.log('queryquery', query.value)
  palleteStore.getComponents(query.value)
  filters_show.value = false

  // const queryString = `/api/v1/components?${queryParams}`;

  // console.log(queryString);

  // fetch.request(queryString, {
  // }).then((data) => {
  //   // console.log('data', data)
  //   palleteStore.components = data
  //   filters_show.value = false
  // })

}


const componentsByCategoryId = computed(() => {
  const result = {}
  // console.log('*** palleteStore.components: ', palleteStore.components)
  for (const component of palleteStore.components) {
    if (component?.category?.id) {
      if (!(component.category.id in result)) {
        result[component.category.id] = []
      }
      result[component.category.id].push(component)
    }


  }
  return result
})


const componentContextMenuHandler = (component) => {
  if (authStore.isAuthenticated) {
    palleteStore.form.selected = component
    editorStore.selected = [{'component': component}]
    for (const category of palleteStore.categories) {
      if (category.id === palleteStore.form.selected.category.id) {
        palleteStore.form.selected.category = category
        break
      }
    }
    // palleteStore.form.selected.category =
    palleteStore.form.creating = false
    return false
  }
}


const createButtonHandler = (event) => {
  palleteStore.form.selected = {
    category: {},
    ports: [],
    parameters: {},
  }
  palleteStore.form.creating = true
}


const mouseDownHandler = (event, component) => {
  editorStore.tmpComponent = component
  if (event.button === 0) {
    editorStore.mode = editorModes.objectPointerDown
  }
}

onBeforeMount(async () => {
  await palleteStore.getCategories()
  await palleteStore.getComponents(query.value)
})

const data = reactive({
  showProjectMenu: false,
  isButtonHovered: false,
  switchState: 1,
  showPallete: true,
  items: [],
  input: ''
})

// const getElements = async () => {
//   data.items = await fetch.request(`/elements?query_string=${data.input}`)
//   console.log('looooooooollll!@!!!!!', data.input)
//   console.log(data.items)
// }


const clearInput = () => {
  query.value = null
}

onMounted(async () => {
  // await getElements()
})

watch(() => data.input, async (value) => {
  // await getElements()
});


const hideElement = (event) => {
  const rect = myElement.value.getBoundingClientRect();
  console.log('zafasfafaf', rect)
  console.log('clientx', event.clientY)

  if (event.clientY < rect.y) {
    data.showProjectMenu = false;
  }

}

const changeShowPallete = () => {
  // data.showPallete = !data.showPallete
  is_expand.value = !is_expand.value
}
</script>

<style>

.category {

}

.category:hover {
}

.component {
  cursor: pointer;

}

.component:hover {
  background-color: #177e6c22;
}

.component-selected {
  background-color: #177e6c55;
}

.button:hover > .icon {
  filter: invert(1);
}

</style>